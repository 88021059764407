<script lang="ts" setup>
import DropTarget from '@uppy/drop-target'
import { Dashboard } from '@uppy/vue'
import FileInput from '@uppy/file-input'
import { ImportSummaryType } from '@workflow'
import { useUploadStore } from '~/stores/upload'

// META
definePageMeta({
  middleware: ['has-company-access'],
})

// GLOBAL
const route = useRoute('division-companyId-workflow')
const { currentDivision, createDivisionURL } = useDivisions()

// ACTIVE DOCUMENTS
const {
  activeTotalCount,
  reviewTotalCount,
  failedTotalCount,
  totalItemsCount,
  refetch: refetchActiveDocuments,
} = useApiImportDocumentsActive(route.params.companyId)

const importSummaries = reactive([
  {
    label: 'To be reviewed',
    number: reviewTotalCount,
    total: totalItemsCount,
    type: ImportSummaryType.Ready,
    to: createDivisionURL(`/workflow`),
  },
  {
    label: 'Processing',
    number: activeTotalCount,
    total: totalItemsCount,
    type: ImportSummaryType.Processing,
    to: createDivisionURL(`/workflow/processing`),
  },
  {
    label: 'Failed',
    number: failedTotalCount,
    total: totalItemsCount,
    type: ImportSummaryType.Failed,
    to: createDivisionURL(`/workflow/failed`),
  },
])

// UPLOADER
const uploadStore = useUploadStore()
const uploader = uploadStore.uploader(route.params.companyId)
const isUploading = computed(() =>
  uploadStore.files.uploading.some(
    (file) => file.company.id === route.params.companyId,
  ),
)
const hasUploading = computed(() => {
  return uploadStore.files.finished.some(
    (file) => file.company.id === route.params.companyId,
  )
})
const uploadingFiles = computed(() => hasUploading.value || isUploading.value)

// refetch documents on upload
uploader.on('upload-success', () => {
  refetchActiveDocuments()
})

// init uploader
const uploadTarget = ref<HTMLElement | null>(null)
const fileSelector = ref<HTMLElement | null>(null)
onMounted(() => {
  const dropTarget = uploader.getPlugin('DropTarget')
  const fileInput = uploader.getPlugin('FileInput')

  if (dropTarget) {
    uploader.removePlugin(dropTarget)
  }
  if (fileInput) {
    uploader.removePlugin(fileInput)
  }

  uploader.use(DropTarget, {
    target: uploadTarget.value!,
  })
  uploader.use(FileInput, {
    target: fileSelector.value!,
    locale: {
      strings: {
        chooseFiles: 'Browse',
      },
    },
  })
})
</script>

<template>
  <div
    :ref="
      (el) => {
        uploadTarget = el as HTMLElement
      }
    "
    :data-company-name="currentDivision?.name"
    class="flex w-full flex-col"
  >
    <!-- Upload document -->
    <div
      class="relative mt-4 hidden w-full flex-col justify-between px-4 md:flex md:flex-row md:px-6"
    >
      <div
        class="flex w-full flex-col transition-all md:pb-0"
        :class="[uploadingFiles ? 'pb-4' : 'pb-0']"
      >
        <section
          class="relative my-4 flex w-full flex-col rounded-lg border-2 border-dashed border-gray-300 bg-gray-200 py-10 transition-all dark:border-gray-700 dark:bg-gray-800"
          :class="[uploadingFiles ? 'py-4' : 'py-10']"
        >
          <span
            class="bg-primary/5 after:border-primary relative flex self-center rounded-full transition-all after:absolute after:inset-0 after:rounded-full after:border after:border-dashed after:content-['']"
            :class="[
              isUploading
                ? 'after:animate-spin-10-sec mb-2 p-2'
                : 'after:animate-spin-1-min mb-4 p-4',
            ]"
          >
            <NuxtIcon
              name="icon-upload-cloud"
              class="text-primary flex items-center justify-center"
              :class="{
                'h-7 w-7 text-2xl': !uploadingFiles,
                'h-5 w-5': uploadingFiles,
              }"
            />
          </span>

          <span
            class="flex items-center justify-center gap-2 font-bold text-white"
            :class="{
              'text-lg': uploadingFiles,
              'text-xl': !uploadingFiles,
            }"
          >
            Drag & Drop
            <span v-if="isUploading">more</span>
            Files, or
            <span ref="fileSelector" class="uppy-file-selector" />
          </span>
          <div
            v-if="!uploadingFiles"
            v-auto-animate
            class="mt-4 px-4 text-center text-sm text-gray-400"
          >
            <p>
              Please ensure that the PDF document is legible and less than 20mb
            </p>
            <p>
              Upload speed is determined by the internet connection being used
            </p>
          </div>
          <!-- Progress bar -->
          <div
            v-if="isUploading"
            class="absolute bottom-px left-1 right-1 h-0.5 rounded-full"
          >
            <div
              class="animate-start-material-progress bg-primary relative m-0 h-full overflow-hidden"
            >
              <div class="bar animate-bar1"></div>
              <div class="bar animate-bar2"></div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Documents list workflow -->
    <section class="mt-4 w-full py-2 md:px-6">
      <div
        class="flex flex-col-reverse items-center justify-between md:flex-row"
      >
        <LazyWorkflowBanner
          :summaries="
            importSummaries.filter(
              (summary) =>
                !(summary.number === 0) ||
                summary.type === ImportSummaryType.Ready,
            )
          "
          class="mb-4 flex-none"
        />
      </div>

      <div>
        <NuxtPage />
      </div>
    </section>

    <div v-show="false" class="mt-4 w-full p-4">
      <h2 class="text-2xl font-semibold">Upload PDFs</h2>
      <Dashboard :uppy="uploader" :plugins="[]" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
:deep(.uppy-Dashboard-inner) {
  @apply !h-[200px] !w-full bg-neutral-200 md:!h-[500px] dark:bg-gray-700;
}

:deep(.uppy-Dashboard-AddFiles-title) {
  @apply text-black dark:text-white;
  button {
    @apply text-primary;
  }
}

:deep(.uppy-Dashboard-poweredBy) {
  @apply hidden;
}

.uppy-is-drag-over {
  &::before {
    @apply top-navigation bg-primary-50/80 fixed bottom-0 left-0 right-0 z-[1000] flex items-center justify-center rounded-lg border-4 border-dashed pb-10 text-center text-4xl font-bold text-black backdrop-blur-3xl backdrop-saturate-[180%];
    content: attr(data-company-name);
  }

  &::after {
    @apply border-primary fixed bottom-1 left-1 right-1 top-[64px] z-[1001] flex items-center justify-center rounded-lg pt-10 text-center text-2xl font-bold text-black;
    content: 'Drag and drop PDF files here';
  }
}

:deep(.uppy-FileInput-btn) {
  @apply text-primary rounded-lg bg-transparent hover:underline;
}
</style>
